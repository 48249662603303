// Fonts
@import url("https://fonts.googleapis.com/css?family=Heebo:100,300,400,500,700,800,900");
@font-face {
    font-family: 'Circular Std';
    src: url('fonts/CircularStd-Medium.eot');
    src: url('fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('fonts/CircularStd-Medium.woff2') format('woff2'),
        url('fonts/CircularStd-Medium.woff') format('woff'),
        url('fonts/CircularStd-Medium.ttf') format('truetype'),
        url('fonts/CircularStd-Medium.svg#CircularStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'fonts/Circular Std';
    src: url('fonts/CircularStd-Bold.eot');
    src: url('fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('fonts/CircularStd-Bold.woff2') format('woff2'),
        url('fonts/CircularStd-Bold.woff') format('woff'),
        url('fonts/CircularStd-Bold.ttf') format('truetype'),
        url('fonts/CircularStd-Bold.svg#CircularStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('fonts/CircularStd-Black.eot');
    src: url('fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('fonts/CircularStd-Black.woff2') format('woff2'),
        url('fonts/CircularStd-Black.woff') format('woff'),
        url('fonts/CircularStd-Black.ttf') format('truetype'),
        url('fonts/CircularStd-Black.svg#CircularStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std Book';
    src: url('fonts/CircularStd-Book.eot');
    src: url('fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('fonts/CircularStd-Book.woff2') format('woff2'),
        url('fonts/CircularStd-Book.woff') format('woff'),
        url('fonts/CircularStd-Book.ttf') format('truetype'),
        url('fonts/CircularStd-Book.svg#CircularStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



// Variables
@import 'variables';
// $navbar-light-toggler-icon-bg: none;
// $navbar-dark-toggler-icon-bg: none;
$principal: #394049;
$gris: #5b6770;
$naranja: #ff8200;

// Bootstrap
@import '~bootstrap/scss/bootstrap';

body {
	@media(max-width: 1441px) {
		font-size: 12px;
	}
	@media(max-width: 1439px) {
		font-size: 11px;
	}
	@media(max-width: 1281px) {
		font-size: 10px;
	}
	@media(max-width: 1023px) {
		font-size: 7px;
	}
}
#app {
	font-family: "Heebo", sans-serif;
	background: $principal;
	.navbar {
		background: $principal;
		padding: 1.9em 2.1em 1.7em;
		.navbar-brand {
			img {
				height: 2.45em;	
			}
		}
		img {
			height: 2.9em;
		}
		#navbarSupportedContent {
			ul {
				justify-content: space-between;
    			flex-grow: 1;
    			padding: 0 3% 1em;
    			@media(max-width: 1025px) {
    				padding: 1em 0 0;
    				li {
    					text-align: center;
    					padding: 0.2em 0;
    					font-size: 1.5em;
    				}
    			}
    			&+div {
    				@media(max-width: 1025px) {
    					display: none;
    				}
    			}
			}
		}
		.navbar-nav {
			padding-bottom: 1em;
			.nav-link {
				text-transform: uppercase;
				font-size: 1.05em;
				color: white;
				letter-spacing: -0.02em;
			}
		}
	}
	.container {
		@media (min-width: 1660px) {
			max-width: 1660px;
			margin: auto;
		}
		&#bienvenida {
			padding: 3.4em 7.3%;
			.height {
				height: 90vh;
				@media(orientation: portrait) {
					height: auto;
				}
			}
		}
	}
	.contenedor {
		padding: 0 7.3%;
		max-width: 100%;
	}
	#amenities {
		background: $naranja;
		color: white;
    	padding: 2.2em 10.7% 1.7em;
    	@media(max-width: 767px) {
    		padding: 2em 10px;
    	}
    	.amenity {
    		img {
    			width: 6em;
			    height: 4.6em;
			    object-fit: contain;
    		}
    		span {
    			text-transform: uppercase;
    			margin-top: 0.5em;
			    max-width: 7em;
			    text-align: center;
			    line-height: 1.3em;
			    font-weight: normal;
			    letter-spacing: 0.09em;
    		}
    	}
	}
	#areas, #piloto, #recorrido, #emplazamiento, #elevaciones, #plantas, #tipologias, #avances, #renders, #post_mantra, #post_avances {
		h1 {
			font-family: $font-circular;
			margin: 0.45em -5px 0.65em;
    		color: $naranja;
		    font-size: 4.1em;
		    font-weight: 600;
		    letter-spacing: 0.06em;
		}
	}
	#areas, #renders {
		.slider {
			height: 70.7vh;
			@media(orientation: portrait) {
				height: auto;
			}
			.slick-prev {
				left: -70px;
			}
			.slick-next {
				right: -70px;
			}
		}
	}
	#piloto, #recorrido, #elevaciones, #post_avances {
		padding: 6.2em 3.2em 0;
		@media(max-width: 767px) {
			padding: 2em 7.3%;
		}
		h1 {
			width: 3.7em;
			span {
				position: absolute;
			    bottom: 9%;
    			left: 5.7%;
				display: block;
				transform-origin: 0 0;
				transform: rotate(-90deg);
				-webkit-transform: rotate(-90deg);
				-moz-transform: rotate(-90deg);
				-ms-transform: rotate(-90deg);
				-o-transform: rotate(-90deg);
				filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
			}
			@media(orientation: portrait) and (max-width: 767px) {
				width: auto;
				span {
					position: relative;
					transform: none;
					-webkit-transform: none;
					-moz-transform: none;
					-ms-transform: none;
					-o-transform: none;
					botton: 0;
					left: 0;
				}
			}
		}
		.slider {
			flex-grow: 1;
			@media(max-width: 1025px) {
				min-height: 50vh;
				@media(orientation: landscape) {
					min-height: 90vh;
				}
				.slick-list, .slick-track, .slick-slide, .height {
					height: 100%;
					position: relative;
				}
			}
			@media(max-width: 767px) {
				min-height: auto;
			}
		}
	}
	#recorrido {
		margin-bottom: 8em;
		h1 {
			span {
				color: white;
				bottom: 8%;
			}
		}
	}
	#emplazamiento, #elevaciones, #plantas, #tipologias, #post_mantra {
		background: white;
	}
	#emplazamiento, #post_mantra {
		h1 {
			padding: 0.3em 3%;
		}
		&.contenedor {
			padding: 0 3.3% 5.3em;
		}
	}
	#elevaciones {
		padding-bottom: 5.3em;
		h1 {
			width: 4.2em;
			span {
				bottom: 24.1%;
				color: $principal;
			}
		}
	}
	#plantas {
		h1 {
			margin-bottom: 0;
		}
		&.contenedor {
			padding: 0 7% 0 2%;
		}
		.slider {
			// max-width: 1240px;
			// margin: auto;
			.slick-prev, .slick-next {
				&:before {
					color: $principal;
				}
			}
			.slick-prev {
				&:before {
					content: '‹';
				}
			}
			.slick-next {
				&:before {
					content: '›';
				}
			}
		}
	}
	#tipologias {
		h1 {
			position: relative;
			margin-bottom: 2.2em;
			&:after {
				content: '';
				position: absolute;
				width: 100%;
				height: 15px;
				background: $principal;
				margin-left: 0.75em;
    			margin-top: 0.45em;
			}
		}
		#especs, #minimap {
			@media(max-width: 767px) {
				padding-left: 10%;
			}
		}
		#minimap {
			@media(max-width: 767px) {
				padding-bottom: 3em;
			}
		}

		&.contenedor {
			padding: 2.4em 5% 7.5em;
		}
		.slider {
			max-width: 1305px;
			margin: auto;
			.slick-prev {
				left: -10%;
			}
			.slick-next {
				right: -10%;
			}
			.slick-prev, .slick-next {
				&:before {
					color: $naranja;
				}
			}
		}
	}
	#avances {
		padding: 4em 0;
		@media(orientation: portrait) {
			padding-bottom: 15em;
		}
		.avance {
			width: 25.3%;
			@media(max-width: 767px) {
				width: 100%;
			}
			h1 {
				@media(max-width: 767px) {
					padding: 0 7.3%;
				}
				@media(min-width: 768px) {
					margin: 2.8em 6% 0 18%;
				}
			}
		}
		#estados {
			z-index: 1;
			background: white;
			color: $principal;
			top: 61.3%;
		    left: 0;
		    min-width: 42.3%;
			padding: 3.7em 0 0.2em;
			@media(orientation: portrait) {
				min-width: 90%;
				@media(max-width: 767px) {
					width: 100%;
					position: relative!important;
				}
			}
			#barra {
				position: relative;
				background-color: white;
				display: flex;
    			flex-direction: column-reverse;
    			#listita {
    				display: flex;
    				// padding-left: 20%;
    				padding-left: 18.5%;
				    justify-content: space-between;
				    width: 110%;
    			}
				.estado {
					.punto {
					    width: 4px;
					    height: 10px;
					    background: transparent;
					    display: block;
					    margin-left: -2px;
					    &.actual {
					    	// background-color: orange;
					    }
					}
					p {
					    text-transform: uppercase;
					    font-weight: 500;
					    font-size: 1.4em;
					    white-space: nowrap;
					    margin: 0;
					    padding: 1.2em 0;
					    letter-spacing: 0.02em;
					}
				}
				.barra {
					background: $naranja;
					height: 30px;
					z-index: 2;
					&+span {
						font-size: 6.5em;
						font-weight: bold;
						line-height: 0.6em;
						margin-left: 6.5%;
					}
				}
			}
			#porcentaje {
				display: flex;
				align-items: flex-end;
				color: $naranja;
			}
		}
		.slider {
			width: 74.6%;
			@media(max-width: 767px) {
				width: 100%;
			}
		}
	}
	#post_avances {
		padding-bottom: 4em;
	}
	#ubicacion {
		background: $principal;
		display: flex;
		align-items: center;
		@media(max-width: 1025px) {
			padding-bottom: 4em;
		}
		@media(max-width: 767px) {
			flex-direction: column;
			padding: 0;
		}
		@media (orientation: portrait) {
			padding-bottom: 15em;
		}
		#gmaps {
			display: flex;
		    width: 100%;
		    height: 100%;
		    @media(orientation: portrait) and (max-width: 767px) {
		    	flex-direction: column;
		    }
		}
		#info_contacto {
			padding: 5em 5%;
			padding-right: 0;
			display: flex;
		    flex-direction: column;
		    justify-content: flex-start;
		    align-items: flex-start;
			-ms-flex-preferred-size: 0;
		    flex-basis: 0;
		    -ms-flex-positive: 1;
		    flex-grow: 1;
		    color: white;
		    @media(orientation: portrait) and (max-width: 767px) {
		    	width: 100%;
		    	padding: 0 5%;
		    	text-align: center;
		    	align-items: center;
		    	font-size: 1.7em;
		    	img {
		    		display: none;
		    	}
		    }
			img {
				// max-width: 100%;
				max-width: 230px;
			    margin-bottom: 1.2em;
			    margin-left: -3px;
			    margin-top: -0.4em;
			    @media(max-width: 1441px) {
			    	max-width: 190px;
			    }
			    @media(max-width: 1025px) {
			    	max-width: 160px;
			    }
			}
			p {
				font-size: 1.3em;
			    letter-spacing: 0.03em;
			    line-height: 1.2em;
			    margin-top: 1.2em;
				a {
					color: $naranja;
					letter-spacing: 0.04em;
				}
			}
		}
		#mapa {
			position: relative;
			width: 80%;
			@media(max-width: 769px) {
				width: 60%;
			}
			@media(max-width: 767px) {
				width: 100%;
			}
			.dummy {
				display: block;
				padding-top: 55.7%;
				@media(max-width: 1441px) {
					padding-top: 60%;
				}
				@media(max-width: 1025px) {
					padding-top: 67%;
				}
				@media(max-width: 769px) {
					padding-top: 110%;
				}
				@media(max-width: 767px) {
					padding-top: 100%;
				}
			}
			#map {
				position: absolute;
				height: 100%;
				top: 0;
				left: 0;
				width: 100%;
    			left: 0;
			}
		}
	}
}

main {
	padding-top: 4em;
	.slider {
		.slick-prev, .slick-next {
			z-index: 2;
			height: 40px;
			width: 40px;
			@media(max-width: 767px) {
				display: none!important;
			}
		}
		.slick-prev {
			left: 30px;
		}
		.slick-next {
			right: 30px;
		}
		.slick-prev:before, .slick-next:before {
			font-size: 40px;
		}
	}
}

.height, .slick-list, .slick-track, .slick-slide {
    height: 100%;
    @media(orientation: portrait) {
		height: auto;
	}
}

section, iframe {
	position: relative;
	overflow-x: hidden;
	@media(max-width: 1025px) {
		.slider {
			max-width: 100%;
		}
	}
	@media(orientation: landscape) and (min-width: 1025px) {
		min-height: calc(100vh - 10em);
	}
}

.fit {
	object-fit: cover;
}

.slick-dots {
	bottom: 4em;
    &> li {
        display: inline-block;
    }
    &> li:only-child {
        display: none;
    }
}

.slick-slide img {
	object-fit: cover;
}

.ZoomContainer {
    pointer-events: none;
}

#tipologias {
	.tipologia {
		h2 {
			font-size: 3.3em;
    		margin: 0.9em -1px 0;
		}
		h5 {
			font-size: 2.7em;
		    margin: 0.3em -3px 0.6em;
		    letter-spacing: 0.05em;
		}
		h6 {
			font-size: 2.3em;
		    font-weight: 500;
		    letter-spacing: 0.05em;
		    margin-bottom: 0;
    		line-height: 1.2em;
		}
		button {
			background: $naranja;
			color: white;
			text-transform: uppercase;
			border: none;
			font-weight: 500;
		    font-size: 2.1em;
		    margin-bottom: 1.2em;
		    margin-top: 2.1em;
		    padding: 4px 1em;
		    letter-spacing: 0.08em;
		}
		.row {
		    display: flex;
		    align-items: stretch;
		    height: 100%;
		}
	}
	.orientacion {
	    max-width: 80%;
	    img {
	    	max-width: 100%;
	    }
	}
	.plano {
		width: 92%;
		margin-left: 9%;
		@media(max-width: 767px) {
			width: 100%;
			margin-left: 0%;
		}
	}
	.terminaciones {
		background: white;
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    opacity: 0;
	    pointer-events: none;
	    transition: 0.4s;
	    padding: 2em;
	    p, ul {
	    	font-size: 1.2em;
	    }
	    &.visible {
	    	pointer-events: auto;
	    	opacity: 1;
	    }
	}
}
#zoom {
	max-width: 1590px;
}
#plantas {
	background: white;
	.slider, .slick-list, .slick-track {
		height: 100%;
	}
}
.slider .zoomist-wrapper {
	background: white;
}

#alerta {
	position: fixed;
	color: $naranja;
	background: $principal;
	padding: 2em 25px 0.5em;
	text-align: center;
	bottom: 0;
	left: 0;
	z-index: 200000;
	@media(max-width: 767px) {
		h1 {
			font-size: 2.5em;
		}
	}
	@media(orientation: landscape) {
		display: none;
	}
}